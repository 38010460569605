import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledImage from './StyledImage';
import closeimg from '../public/img/close-icn.svg';
import {
  fetchAdvisedUrl,
  getGeolocationCountry,
  getMyIp
} from '../fetchers/geolocation';
import { useRouter } from 'next/router';
import {
  DEFAULT_LANG,
  getLangCountry,
  SUPPORTED_LANG
} from '../helpers/languages';
import { useCookies } from 'react-cookie';
import { DEFAULT_COUNTRY } from '../helpers/countries';
import { fetchShippingCountriesList } from '../fetchers/shipping';
import { isEmpty } from 'lodash';
import { useSessionStorage } from '../hooks/useSessionStorage';

const AdviseCountry = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [translatedUrl, setTranslatedUrl] = useState('');
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const [, setCookie] = useCookies(['prima_bearer']);
  const shippingCountriesList = fetchShippingCountriesList({ lang });
  const [geoCountry, setGeoCountry] = useSessionStorage('prima_geo_country'); // è il campo 'country-code' della risposta 'geolocation' non la storeview

  useEffect(() => {
    checkCountryGeolocation();
  }, []);

  useEffect(() => {
    getTranslation();
  }, [country, router.query, geoCountry]);

  const getUrl = () => {
    const { query, asPath } = router;
    if (!isEmpty(query)) {
      return query.slug || '';
    }
    const slugs = asPath.split('/').filter(s => s);
    slugs.splice(0, 1); // rimuovo il lang_country
    return slugs;
  };

  const geoStoreView = () => {
    if (!geoCountry) return '';
    const country = shippingCountriesList.find(
      c =>
        c['country-code']?.toLowerCase() ===
        (geoCountry as string).toLowerCase()
    );
    return country ? country.storeview : '';
  };

  const getTranslation = async () => {
    if (!country || !geoCountry || country === geoStoreView()) {
      return;
    }

    const shownAdvice =
      sessionStorage.getItem('advise_country_shown') === 'true';
    if (shownAdvice) {
      return;
    }

    const url = getUrl();
    if (!url) {
      return;
    }

    const data = await fetchAdvisedUrl({
      url: getUrl(),
      lang,
      country
    });

    if (
      (data.type === 'product' || data.type === 'category') &&
      !Array.isArray(data.translations)
    ) {
      const storeview = geoStoreView();
      if (!storeview) {
        return;
      }

      setTranslatedUrl(
        `/${lang}_${storeview}${data.type === 'category' ? '/shop' : '/'}` +
          data.translations[lang]
      );

      setIsOpen(true);
    }
  };

  const checkCountryGeolocation = async () => {
    if (geoCountry) {
      return;
    }

    const ip = await getMyIp();

    if (!ip) {
      return;
    }

    const newGeoCountry = await getGeolocationCountry(ip);
    if (typeof setGeoCountry === 'function') {
      setGeoCountry(newGeoCountry);
    }
  };

  const onCancel = () => {
    setIsOpen(false);
    sessionStorage.setItem('advise_country_shown', 'true');

    const actualCountry = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === country.toLowerCase()
    );

    if (actualCountry) {
      setCookie(
        'prima_actual_country',
        actualCountry['country-code']?.toLowerCase(),
        {
          path: '/'
        }
      );
    }
  };

  const onChangeConfirm = async () => {
    const actualCountry = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === String(geoCountry).toLowerCase()
    );

    if (actualCountry) {
      setCookie(
        'prima_actual_country',
        actualCountry['country-code']?.toLowerCase(),
        {
          path: '/'
        }
      );
    }

    setCookie('prima_country', geoStoreView(), {
      path: '/'
    });

    window.location.href = translatedUrl;

    setIsOpen(false);
  };

  const getCountryName = () => {
    const country = shippingCountriesList.find(
      c =>
        c['country-code']?.toLowerCase() ===
        (geoCountry as string).toLowerCase()
    );

    return country ? country['country-name'] : '';
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-5xl pb-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="div"
                className="flex justify-between items-center text-lg font-medium text-black bg-gray-100 px-6 py-4"
              >
                <h3 className="text-2xl text-black">
                  {t('FROM')} {getCountryName()}?
                </h3>
                <button
                  className="text-center bg-white text-primary rounded-full w-8 h-8 flex justify-center items-center"
                  onClick={onCancel}
                >
                  <StyledImage
                    src={closeimg}
                    className="flex justify-center align-center"
                    alt="close icon"
                  />
                </button>
              </Dialog.Title>
              <div className="pt-6 px-6 flex flex-col md:flex-row items-center justify-between">
                <p>{t('COUNTRY_WEBSITE')}</p>
              </div>
              <div className="mt-4 flex px-6">
                <button
                  type="button"
                  className="inline-flex mr-5 justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-sm hover:bg-black hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={onChangeConfirm}
                >
                  {t('YES_GO')} {getCountryName()} website!
                </button>

                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary bg-white border border-primary rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={onCancel}
                >
                  {t('NO_STAY')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdviseCountry;
